import {PaginationResponse} from "@intrerfaces/global/request.interface";
import {AddressInterface} from "@intrerfaces/user/modules/address.interface";
import {ProductResourceInterface} from "@intrerfaces/user/modules/product-resource.interface";
import {ShopInterface} from "@intrerfaces/user/modules/shop.interface";
import {DeliverStatusEnum} from "@intrerfaces/user/modules/deliver-status.enum";
import {PromoAmountTypeEnum} from "@intrerfaces/user/modules/promo-code.interface";
import {OrderReviewInterface} from "@intrerfaces/user/modules/order-review.interface";

export interface OrderBaseInterface {
    id: number,
    deliverOn: string
    note: string
    address: AddressInterface,
    totalCount: number
    totalAmount: number
    deliveryPrice: number
    status: OrderStatusEnum,
    paymentStatus: 'CREATED' | 'APPROVED' | 'DEPOSITED' | 'CANCELLED' | 'REFUNDED'
    totalPreparationTime: string
    deliveredOn: string
    createdAt: string,
    shop?: ShopInterface,
    diapason?: number,
    promoCode?: string,
    statusUpdatedAt?: string,
    orderReview: OrderReviewInterface
}

export enum OrderStatusEnum {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    COLLECTED = 'COLLECTED',
    ON_THE_WAY = 'ON_THE_WAY',
    DELIVERED = 'DELIVERED',
    CANCELED = 'CANCELED'
}

export const statusesWithText = {
    [OrderStatusEnum.COLLECTED]: 'Собран',
    [OrderStatusEnum.ON_THE_WAY]: 'В пути',
    [OrderStatusEnum.DELIVERED]: 'Доставлен',
    [OrderStatusEnum.CANCELED]: 'Отменен',
    [OrderStatusEnum.PENDING]: 'Ожидает',
    [OrderStatusEnum.ACCEPTED]: 'Принят'
}

export const buttonStatusText = {
    [OrderStatusEnum.ACCEPTED]: 'Товар собран! 💐',
    [OrderStatusEnum.COLLECTED]: 'Отдаём курьеру! 📦',
    [OrderStatusEnum.ON_THE_WAY]: 'Заказ выполнен! 🥰',
    [OrderStatusEnum.DELIVERED]: '',
    [OrderStatusEnum.CANCELED]: '',
    [OrderStatusEnum.PENDING]: ''
}

export const descriptionsByStatus = {
    [OrderStatusEnum.PENDING]: "Заказ ожидает вашего принятия, как только вы нажмёте кнопку ниже, клиенту будет отправлено уведомление",
    [OrderStatusEnum.ACCEPTED]: "Сейчас клиент видит, что вы приняли его заказ, не забудьте поменять статус, как только начнёте сборку товара",
    [OrderStatusEnum.COLLECTED]: "Снимите видео от 5 до 15 секунд перед отправкой букета и дождитесь ответа",
    [OrderStatusEnum.ON_THE_WAY]: 'Не забудьте отметить, что заказ доставлен, чтобы он успешно закрылся, вы всегда сможете найти его в “истории заказов”',
    [OrderStatusEnum.DELIVERED]: 'Спасибо за успешное выполнение заказа! ❤ Вы можете оставить свои комментарии и предложения нашему менеджеру',
    [OrderStatusEnum.CANCELED]: 'Заказ был отменен',
}

export interface OrderPaginationInterface extends PaginationResponse {
    content: Array<OrderInterface>
}

export interface OrderInterface extends OrderBaseInterface {
    receiverName: string,
    receiverPhoneNumber?: string,
    resourceUrls?: Array<ProductResourceInterface>
}


export interface SingleOrderInterface extends OrderBaseInterface {
    sender: {
        id: number
        firstName: string
        lastName: string
        phoneNumber: string
    },
    receiver: {
        receiverName: string
        receiverPhoneNumber: string
    }
    orderItems: Array<{
        count: number,
        totalPrice: number,
        productDetails: {
            relationId: number,
            title: string,
            width: string
            height: string
            description: string
            price: number
            shop?: ShopInterface,
            resourceUrls: Array<ProductResourceInterface>
        },
    }>,
    promocode: {
        promo: string,
        amount: 0,
        amountType: PromoAmountTypeEnum
    },
    productTotalAmount: number,
    deliverOnStatus: DeliverStatusEnum,
    orderRate?: OrderRateInterface,
    rate?: number
}

export interface OrderRateInterface {
    priceAndQuality?: number;
    delivery?: number,
    service?: number
    feedback?: string
}


